<script setup lang="ts">
const { showTabs } = withDefaults(
  defineProps<{
    showTabs?: boolean
    showMobileMenu?: boolean
  }>(),
  { showTabs: false, showMobileMenu: true },
)

const { isAuthenticated } = useAuth()

// const { currentDesk } = useCurrentDesk()
// const centerId = useCurrentCenterId()
// const isDev = process.dev
</script>

<template>
  <header class="dark:border-muted-700 border-0 border-b-2">
    <MeedoenHeaderTabs v-if="showTabs" class="hidden md:flex" />

    <Container
      class="flex h-20 flex-row items-center px-4 md:h-24 md:justify-between"
      :class="{ 'md:mt-4': showTabs }"
    >
      
      <TairoSidebarBurger
        v-if="showMobileMenu"
        class="visible absolute -ml-2 md:hidden"
      />
      <MeedoenLogo class="flex w-full justify-center md:justify-start" />

      <HeaderOrganizationSelect class="mr-4" />

      <div class="hidden items-start gap-3 md:flex">
        
        
        <ThemeToggle />
        
        <ToolbarAccountMenu v-if="isAuthenticated" />
        <ToolbarSignIn v-else />
      </div>
    </Container>
  </header>
</template>

<style scoped>
/* Overwrite the bg-primary-500 for the TairoSidebarBurger  */
/* ::v-deep(.bg-primary-500) {
  --tw-bg-opacity: 1;
  background-color: #ffffff;
} */
</style>
