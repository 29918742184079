<script setup lang="ts">
const { visitorType } = useVisitorType()
const { currentDesk } = useCurrentDesk()

const { organizationsPath } = useAppConfig()

// This makes it easier to switch from organization to newcomer as soon as a choice for a desk is made
// However it makes it a bit header to switch from desk. We should think of a good UI to switch desks.
const newcomerPath = computed(() => {
  if (!currentDesk.value || currentDesk.value.slug == 'balie-nov') {
    return '/kies-taal'
  }
  return `/${currentDesk.value.slug}/nieuwkomer`
})

const buttonStyle = `bg-secondary-500 flex gap-x-2 md:rounded-b-xl py-2 px-4  text-black hover:no-underline md:hover:mt-1 flex-grow w-1/2`
const buttonStyleActive = `text-black hover:text-black text-center`
// On mobile, we use opacity to indicate the active tab
const buttonStyleInactive = `text-muted-600 hover:text-primary-500 text-opacity-50 hover:text-opacity-100 opacity-80 md:opacity-100`
</script>

<template>
  <div class="absolute top-0 w-full justify-center">
    <div
      class="bar bg-secondary-500 absolute z-0 hidden h-4 w-full md:block"
    />
    <nav class="z-10 flex items-start">
      <BaseLink
        :to="newcomerPath"
        :class="[
          buttonStyle,
          visitorType !== 'newcomer' ? buttonStyleInactive : buttonStyleActive,
        ]"
      >
        <Icon
          name="material-symbols:volunteer-activism-outline-rounded"
          class="size-6"
        />
        <BaseParagraph>
          {{ $t('visitorTypes.newcomer') }}
        </BaseParagraph>
      </BaseLink>
      <BaseLink
        :to="organizationsPath"
        :class="[
          buttonStyle,
          visitorType !== 'organization'
            ? buttonStyleInactive
            : buttonStyleActive,
        ]"
      >
        <Icon name="ph:buildings" class="size-6" />
        <BaseParagraph>
          {{ $t('visitorTypes.generic') }}
        </BaseParagraph>
      </BaseLink>
    </nav>
  </div>
</template>
