<template>
  <BaseHeading as="h1">
    <NuxtLink to="/" class="flex items-center gap-x-3">
      <img
        src="/logo-meedoen.png"
        alt="ikdoemee.nl logo"
        class="h-16 dark:rounded-xl"
      >
      <span class="text-xl">ikdoemee.nl</span>
    </NuxtLink>
  </BaseHeading>
</template>

<style scoped></style>
