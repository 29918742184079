<script setup lang="ts">
const { currentDesk } = useCurrentDesk()
</script>

<template>
  <footer class="bg-primary-500 text-white-500 pb-8 pt-4">
    <Container
      class="flex flex-col-reverse justify-between gap-10 pb-4 pt-6 text-white sm:flex-row"
    >
      
      <div class="flex grow flex-col justify-between">
        <p class="flex items-center gap-x-2 text-sm">
          <img
            class="w-9"
            src="/logo-nov.png"
            alt=""
          >
          Een initiatief van Vereniging NOV
        </p>

        <p class="mt-4 text-sm font-bold">
          Powered by
          <a href="https://www.volunteerly.nl" class="">🙋‍♂️ Volunteerly</a>
        </p>
      </div>

      <slot />
    </Container>
  </footer>
</template>
